import React from "react";
import { useParams } from "react-router-dom";
import { Banner } from "../Banner";
import { Helmet } from "react-helmet";

export const BlogDetails = () => {
  const { id } = useParams();
  return (
    <>
     <Helmet>
        <title>Blog Details - Winagencydesigns</title>
        <meta
          name="description"
          content="Discover our top-notch services in web design, development, and digital strategy. Transform your business with cutting-edge technology and expert solutions."
        />
        <link rel="canonical" href="https://www.winagencydesigns.com/" />
        <meta property="og:title" content="Blog Details - Winagencydesigns" />
        <meta
          property="og:description"
          content="We offer premium web design, development, and digital solutions tailored to your business needs."
        />
        <meta property="og:url" content="https://www.winagencydesigns.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.winagencydesigns.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blog Details - Winagencydesigns" />
        <meta
          name="twitter:description"
          content="Explore expert web development, UI/UX design, and digital transformation services."
        />
        <meta name="twitter:image" content="https://www.winagencydesigns.com/" />

        {/* JSON-LD Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Winagencydesigns",
            "url": "https://www.winagencydesigns.com/",
            "description":
              "We specialize in web design, development, and digital solutions for businesses of all sizes.",
            "publisher": {
              "@type": "Organization",
              "name": "Winagencydesigns",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.winagencydesigns.com/"
              }
            }
          })}
        </script>
      </Helmet>
      <div className="bg-dark text-black mt-20 pb-8">
        <Banner
          title={`Web Design Blog - ${id}`}
          img={
            "https://images.pexels.com/photos/6476593/pexels-photo-6476593.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
        />
        <div className="max-w-7xl mx-auto px-4 pt-6">
          <p className="py-2 opacity-60">10 Feb 2024</p>
          <h1 className="uppercase text-2xl title">
            The Future of Web Design: Emerging Trends & Technologies
          </h1>
          <div className="flex items-center space-x-2 pt-4">
            <img
              className="rounded-full w-10 h-10 object-cover"
              src="https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Alex Smith"
            />
            <div>
              <h1 className="font-semibold">Alex Smith</h1>
              <p className="opacity-60">Author</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="py-8 px-4 max-w-7xl mx-auto">
          <p className="opacity-60">
            Web design is constantly evolving, with new trends and technologies shaping the way we create digital experiences. In this article, we explore the latest innovations that are defining the future of web design.
          </p>
        </div>

        <div className="max-w-7xl mx-auto px-4 pb-8">
          <h1 className="title text-2xl pb-4">Key Trends in Web Design</h1>
          <div className="md:flex items-center justify-between">
            <div>
              <h1 className="font-semibold">Popular Trends</h1>
              <ul className="list-decimal list-inside opacity-60 pt-2">
                <li>Neumorphism & Glassmorphism</li>
                <li>Dark Mode UI</li>
                <li>AI-Driven Personalization</li>
              </ul>
            </div>
            <div className="pt-4 md:pt-0">
              <h1 className="font-semibold">Design Best Practices</h1>
              <ul className="list-disc list-inside opacity-60 pt-2">
                <li>Mobile-First Approach</li>
                <li>Accessibility & Inclusive Design</li>
                <li>Performance Optimization</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 pb-8">
          <h1 className="title text-2xl">Why Web Design Matters</h1>
          <img
            className="w-full rounded-xl h-80 object-cover mt-4"
            src="https://images.pexels.com/photos/3584993/pexels-photo-3584993.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Web Design Importance"
          />
          <p className="opacity-60 py-2">
            A well-designed website enhances user experience, boosts engagement, and improves conversion rates. Investing in high-quality web design ensures that your brand remains relevant and competitive in the digital landscape.
          </p>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-8 lg:flex lg:space-x-4">
          <div className="lg:w-1/2 flex space-x-4 opacity-60 items-center">
            <hr className="bg-[#3d3d3d] w-4 rounded-full h-40" />
            <p>
              The key to great web design lies in understanding user behavior, following design principles, and embracing new technologies. By prioritizing usability and aesthetics, designers can create seamless digital experiences that stand out.
            </p>
          </div>
          <img
            className="w-full lg:w-1/2 rounded-xl h-80 object-cover mt-4 lg:mt-0"
            src="https://images.pexels.com/photos/3184454/pexels-photo-3184454.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="UX Design Process"
          />
        </div>

        <div className="max-w-7xl mx-auto px-4 pb-8">
          <h1 className="title text-2xl">Innovations Shaping the Future</h1>
          <img
            className="w-full rounded-xl h-80 object-cover mt-4"
            src="https://images.pexels.com/photos/3182821/pexels-photo-3182821.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Future of Web Design"
          />
          <p className="opacity-60 py-2">
            With advancements in AI, AR/VR, and interactive experiences, the web design landscape is evolving faster than ever. Staying updated with the latest tools and technologies can help designers craft unique and engaging digital products.
          </p>
        </div>
      </div>
    </>
  );
};