import React from "react";
import { FaArrowDown } from "react-icons/fa";

const steps = [
  { id: 1, title: "Initial Meeting and Understanding", description: "Meet with clients to understand their work and goals." },
  { id: 2, title: "Upfront Payment Collection", description: "Collect 50% of the project cost upfront." },
  { id: 3, title: "Content Gathering", description: "Receive all necessary files or extract content from an existing site." },
  { id: 4, title: "Design and Prototyping", description: "Develop and present design concepts within the first week or two." },
  { id: 5, title: "Development Phase", description: "Start development to bring the design to life." },
  { id: 6, title: "Revisions and Feedback", description: "Meet again to discuss changes and provide up to 5 revisions." },
  { id: 7, title: "Domain Setup and Transfer", description: "Transfer an existing domain or set up a new one." },
  { id: 8, title: "Final Payment and Launch", description: "Collect the remaining balance and launch the website." }
];

const FAQ = () => {
  return (
    <section className="bg-gradient-to-r from-red-500 via-orange-500 to-red-500 py-16 px-6 md:px-16 lg:px-24">
      <div className="text-center mb-12">
        <h1 className="text-5xl font-extrabold text-white mb-4">Our Process Explained</h1>
        <p className="text-xl text-white opacity-80">Discover how we bring your vision to life.</p>
      </div>
      
      <div className="flex flex-wrap justify-center gap-6 relative">
        {steps.map((step, index) => (
          <div 
            key={step.id} 
            className="relative flex flex-col items-center"
            style={{
              transform: `rotate(${Math.random() * 10 - 5}deg)`, // Random rotation for a more dynamic collage feel
              top: `${Math.random() * 20 - 10}px`, // Random vertical offset
              left: `${Math.random() * 20 - 10}px`, // Random horizontal offset
            }}
          >
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-80 text-center">
              <h2 className="text-xl font-semibold text-red-600">Step {step.id}: {step.title}</h2>
              <p className="text-gray-800 dark:text-gray-300 mt-2">{step.description}</p>
            </div>
            {/* {index < steps.length - 1 && <FaArrowDown className="text-white text-3xl mt-4" />} */}
            <div className="absolute -top-2 bg-red-500 w-8 h-4 rounded-full"/>
            <div className="absolute -bottom-2 bg-red-500 w-8 h-4 rounded-full"/>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
