import React from 'react'
import { useParams } from 'react-router-dom';
import FilterObjects from '../FilterObjects'
export const Details = () => {
    let { id } = useParams();
  return (
    <div className='pt-24 px-6 mx-auto w-[80%]'>
    {FilterObjects.map(FilterObject => {
        if(FilterObject.id == id){
          return <div className='relative'>
            <h1 className='text-4xl pb-10'>{FilterObject.name}</h1>
            <img className='rounded-lg w-[800px]' src={FilterObject.imageUrl} alt="" />
            <p>{FilterObject.content}</p>
            <a href={FilterObject.link} target='_blank' className='bg-orange-600 text-white px-4 py-2 rounded-xl'>Live Website</a>
          </div>
        }
    })
    }
    </div>
  )
}
