import React from 'react'
import { Form } from './Form'

export const Rightside = () => {
  return (
    <div className='w-full'>
        <Form/>
    </div>
  )
}
