import React, { useContext } from "react";
import { FaExclamation } from "react-icons/fa6";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { motion } from "framer-motion";
import { AppContextProvider } from "../../App";

const Banner = () => {
  const { handleClickScroll } = useContext(AppContextProvider);

  return (
    <div id="section-1" className="h-full">
      <section
        className="relative bg-gray-900 dark:bg-gray-900 bg-no-repeat bg-cover h-screen"
        style={{
          backgroundImage:
            "url('https://media.istockphoto.com/id/1180452099/photo/salt-lake-city-utah-usa-downtown.jpg?b=1&s=612x612&w=0&k=20&c=6zmpp36PNVzFkuFVX4Vvs67ZRXdKi180zfS637FnzSg=')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>

        <div className="relative flex flex-col justify-center items-center h-full py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-50">
          {/* Alert Banner */}
          <div className="inline-flex justify-between items-center py-1 px-1 pe-4 mb-7 text-sm text-orange-700 bg-orange-100 rounded-full dark:bg-blue-900 dark:text-blue-300 hover:bg-orange-200 dark:hover:bg-blue-800">
            <span className="text-xs bg-orange-600 rounded-full text-white px-4 py-1.5 me-3">
              <FaExclamation />
            </span>
            <span className="text-sm font-medium">
              UI/UX & Graphic Design Services
            </span>
          </div>

          {/* Main Heading */}
          <motion.h1
            viewport={{ once: true }}
            initial={{ scale: 0, opacity: 0, y: 100 }}
            whileInView={{ scale: 1, opacity: 1, y: 0 }}
            transition={{ delay: 0.4, type: "spring", stiffness: 260, damping: 20 }}
            className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-8xl dark:text-white"
          >
            <div className="flex flex-col md:flex-row md:space-x-4 items-center py-3">
              <h1>Designs.</h1>
              <h1 className="pt-2 lg:p-0">Development.</h1>
            </div>
            <h1 className="spaciel-char">Masterpiece.</h1>
          </motion.h1>

          {/* Description */}
          <motion.p
            initial={{ scale: 0, opacity: 0, y: 100 }}
            whileInView={{ scale: 1, opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5, type: "spring", stiffness: 260, damping: 20 }}
            className="mb-8 text-lg font-normal lg:text-xl lg:px-48 text-gray-200  py-2 w-[80%]"
          >
            Elevate your brand with expert UI/UX and Graphic Design services in Salt Lake City, Utah. 
            We specialize in crafting stunning, user-friendly digital experiences for web, mobile, and connected platforms. 
          </motion.p>

          {/* Call to Action */}
          <div className="w-full max-w-md mx-auto">
            <div className="relative">
              <button
                onClick={() => handleClickScroll(2)}
                className="text-white absolute inset-0 w-40 h-16 rounded-full mx-auto mt-5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-orange-700 dark:focus:ring-blue-800"
              >
                Hire Us
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Animated Scroll Indicator */}
      <motion.div
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white"
        initial={{ y: 0 }}
        animate={{ y: 10 }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 1.2 }}
      >
        <MdKeyboardDoubleArrowDown className="text-3xl text-white dark:text-gray-300 animate-pulse" />
      </motion.div>
    </div>
  );
};

export default Banner;
