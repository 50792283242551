import React, { useRef, useState } from 'react'
import { Form } from './Form'
import { FaCheckDouble, FaRegCopy } from "react-icons/fa";
export const Leftside = () => {
const [successMessage, setSuccessMessage] = useState('')
const zoomRef = useRef()
const emailRef = useRef()
const numberRef = useRef()


function copyText(val) {
    // Get the text field
    var text = val;

    // Copy the text inside the text field
    navigator.clipboard.writeText(text);
    
    // // Alert the copied text
    setTimeout(() => {
      setSuccessMessage('');
    }, 2000)
    setSuccessMessage("Copied: " + text);
  }


    
  return (
    <div className='leading-7 w-full'>
          {successMessage &&
  <div className='fixed top-32 inset-0 mx-auto h-14 z-[40000] bg-green-300 text-green-800 w-1/2 rounded-lg px-4 py-1 flex items-center space-x-4'><FaCheckDouble className='text-green-500'/><p>{successMessage}</p></div>
}
        <h1 className='text-orange-600 uppercase'>Inquiry</h1>
        <h1 className='py-4 text-3xl font-bold lg:pb-0'>Let's get in touch</h1>
        <div className='py-10'>
            <div className='group'>
                <p className='text-orange-500 italic tracking-widest font-bold'>We also accept text messages.</p><br />
                <p>Mobile Number</p>
                {/* <input type="text" name='phone' placeholder='+808828999288' className='w-full bg-white rounded-lg h-14 px-4 outline-none' /> */}
                <div className='flex items-center space-x-4'>
                {/* <input className='opacity-0 w-0' type="text" value={'801-223-2838'} /> */}
                <p ref={numberRef}  className='text-xl py-2 group-hover:text-black lg:text-gray-500 text-black'>801-918-1587</p>
                <FaRegCopy onClick={() => copyText(numberRef.current.innerHTML)} className='group-hover:block cursor-pointer block lg:hidden text-xl text-orange-500'/>
                </div>
            </div>
            <div className='py-6 group'>
                <p>Business Email</p>
                <div className='flex items-center space-x-4'>
                {/* <input className='opacity-0 w-0' ref={emailRef} type="text" value={'winagencydesigns@gmail.com'} /> */}
                <p ref={emailRef} className='text-xl py-2 group-hover:text-black lg:text-gray-500 text-black break-all'>winagencydesigns@gmail.com</p>
                <FaRegCopy onClick={() => copyText(emailRef.current.innerHTML)} className='group-hover:block cursor-pointer block lg:hidden text-xl text-orange-500'/>
                </div>
            </div>
           
        </div>
        {/* <div className='pb-20 pt-10'>
        <p className='text-gray-400'>Our Location</p>
        <iframe className='w-[100%] h-60 rounded-xl' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div> */}
    </div>
  )
}
