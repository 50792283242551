import React, { useState } from "react";
import { FiArrowLeft, FiArrowRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

export const RecentBlog = () => {
  const blogPosts = [
    {
      id: 1,
      title: "UI/UX Trends to Watch in 2024",
      description:
        "Explore the latest UI/UX design trends shaping the web in 2024. From neumorphism to glassmorphism, stay ahead of the curve.",
      image:
        "https://images.pexels.com/photos/3184451/pexels-photo-3184451.jpeg",
    },
    {
      id: 2,
      title: "Dark Mode: The Ultimate Guide",
      description:
        "Learn how to implement and optimize dark mode for your web projects. Improve accessibility, aesthetics, and user experience.",
      image:
        "https://images.pexels.com/photos/574070/pexels-photo-574070.jpeg",
    },
    {
      id: 3,
      title: "Best Free Design Tools for Web Designers",
      description:
        "Discover the top free tools that every web designer should have in their toolkit, from wireframing to prototyping.",
      image:
        "https://images.pexels.com/photos/3584995/pexels-photo-3584995.jpeg",
    },
    {
      id: 4,
      title: "Typography in Web Design: Do’s and Don’ts",
      description:
        "Master the art of typography in web design. Learn the best font pairings, readability tips, and common mistakes to avoid.",
      image:
        "https://images.pexels.com/photos/7112674/pexels-photo-7112674.jpeg",
    },
    {
      id: 5,
      title: "CSS Grid vs. Flexbox: Which One to Use?",
      description:
        "A comprehensive guide comparing CSS Grid and Flexbox. Understand when and where to use each for optimal layout design.",
      image:
        "https://images.pexels.com/photos/270632/pexels-photo-270632.jpeg",
    },
    {
      id: 6,
      title: "How to Build a Portfolio Website That Stands Out",
      description:
        "Your portfolio is your digital resume. Learn how to design an eye-catching portfolio that attracts clients and job offers.",
      image:
        "https://images.pexels.com/photos/11035371/pexels-photo-11035371.jpeg",
    },
    {
      id: 7,
      title: "How to Build a Portfolio Website That Stands Out",
      description:
        "Your portfolio is your digital resume. Learn how to design an eye-catching portfolio that attracts clients and job offers.",
      image:
        "https://images.pexels.com/photos/11035371/pexels-photo-11035371.jpeg",
    },
    {
      id: 8,
      title: "How to Build a Portfolio Website That Stands Out",
      description:
        "Your portfolio is your digital resume. Learn how to design an eye-catching portfolio that attracts clients and job offers.",
      image:
        "https://images.pexels.com/photos/11035371/pexels-photo-11035371.jpeg",
    },
    {
      id: 9,
      title: "How to Build a Portfolio Website That Stands Out",
      description:
        "Your portfolio is your digital resume. Learn how to design an eye-catching portfolio that attracts clients and job offers.",
      image:
        "https://images.pexels.com/photos/11035371/pexels-photo-11035371.jpeg",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;
  const totalPages = Math.ceil(blogPosts.length / blogsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(Math.max(1, Math.min(totalPages, pageNumber)));
  };

  const getPageNumbers = () => {
    const maxPagesToShow = 6;
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const currentBlogs = blogPosts.slice(
    (currentPage - 1) * blogsPerPage,
    currentPage * blogsPerPage
  );

  return (
    <div className="py-14 px-4 max-w-7xl mx-auto text-center md:text-left">
      <h1 className="title text-4xl">LATEST IN WEB DESIGN</h1>
      <p className="opacity-60 md:w-3/4">
        Stay updated with the latest trends, tools, and techniques in web design. From UI/UX innovations to front-end development best practices, explore insights from industry experts.
      </p>
      <div className="grid md:grid-cols-3 gap-6 pt-4">
        {currentBlogs.map((blogPost) => (
          <a href={`/details/${blogPost.id}`} key={blogPost.id} className="block transition-transform transform hover:scale-105">
            <img
              className="w-full h-60 object-cover rounded-xl"
              src={blogPost.image}
              alt={blogPost.title}
            />
            <div className="py-2">
              <h1 className="font-semibold uppercase line-clamp-1">
                {blogPost.title}
              </h1>
              <p className="opacity-60 line-clamp-2">{blogPost.description}</p>
            </div>
            <div className="flex items-center space-x-2 pt-4">
              <img
                className="rounded-full w-10 h-10 object-cover"
                src="https://images.pexels.com/photos/1181671/pexels-photo-1181671.jpeg"
                alt="Design Author"
              />
              <div>
                <h1 className="font-semibold">JANE DOE</h1>
                <p className="opacity-60 text-left">Web Designer & Author</p>
              </div>
            </div>
          </a>
        ))}
      </div>
      <div className="flex justify-center items-center space-x-4 pt-6">
        <button
          className="p-2 rounded-full bg-gray-200 text-black"
          onClick={() => handleClick(1)}
          disabled={currentPage === 1}
        >
          <FiChevronsLeft />
        </button>
        <button
          className="p-2 rounded-full bg-gray-200 text-black"
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FiArrowLeft />
        </button>
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            className={`px-3 py-1 rounded-lg ${currentPage === pageNumber ? 'bg-black text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => handleClick(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
        {totalPages > getPageNumbers().length && <span className="px-3 py-1">...</span>}
        <button
          className="p-2 rounded-full bg-gray-200 text-black"
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FiArrowRight />
        </button>
        <button
          className="p-2 rounded-full bg-gray-200 text-black"
          onClick={() => handleClick(totalPages)}
          disabled={currentPage === totalPages}
        >
          <FiChevronsRight />
        </button>
      </div>
    </div>
  );
};
