import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { AppContextProvider } from "../../App";
import { Services } from "../Services/Services";
import { OurWork } from "../../OurWork/OurWork";
import { Tech } from "../TechUsed/Tech";
import { Partner } from "../Partner/Partner";
import { About } from "../About/About";
import { Contact } from "../contact/Contact";
import Footer from "../Footer";
import PricingPlans from "../price/PricingPlans";
import Client from "../Client";
import FAQ from "../FAQ";
import { Why } from "../Why";
import  Banner  from "./Banner";

export const Home = () => {
  const { handleClickScroll } = useContext(AppContextProvider);

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Home - Winagencydesigns</title>
        <meta
          name="description"
          content="Discover our top-notch services in web design, development, and digital strategy. Transform your business with cutting-edge technology and expert solutions."
        />
        <link rel="canonical" href="https://www.winagencydesigns.com/" />
        <meta property="og:title" content="Home - Winagencydesigns" />
        <meta
          property="og:description"
          content="We offer premium web design, development, and digital solutions tailored to your business needs."
        />
        <meta property="og:url" content="https://www.winagencydesigns.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.winagencydesigns.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home - Winagencydesigns" />
        <meta
          name="twitter:description"
          content="Explore expert web development, UI/UX design, and digital transformation services."
        />
        <meta name="twitter:image" content="https://www.winagencydesigns.com/" />

        {/* JSON-LD Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Winagencydesigns",
            "url": "https://www.winagencydesigns.com/",
            "description":
              "We specialize in web design, development, and digital solutions for businesses of all sizes.",
            "publisher": {
              "@type": "Organization",
              "name": "Winagencydesigns",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.winagencydesigns.com/"
              }
            }
          })}
        </script>
      </Helmet>

      {/* Page Content */}
      <Banner />
      <FAQ />
      <Services />
      <OurWork />
      <PricingPlans />
      <Client />
      <Tech />
      <Partner />
      <About />
      <Contact />
      <Footer />
    </>
  );
};
