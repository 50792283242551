import filter1 from '../WorkImg/filter1.jpg'
import filter2 from '../WorkImg/filter2.jpg'
import filter3 from '../WorkImg/filter3.jpg'
import filter5 from '../WorkImg/filter5.png'
import filter6 from '../WorkImg/filter6.png'
import filter7 from '../WorkImg/filter7.png'
import filter8 from '../WorkImg/filter8.png'
import filter9 from '../WorkImg/filter9.png'
import filter10 from '../WorkImg/filter10.png'
import filter11 from '../WorkImg/filter11.jpg'
import filter12 from '../WorkImg/resto copy.png'
const websiteObjects = [
    {
      id: 1,
      name: 'Travel Website',
      type: 'Restuarant',
      content: 'This is our beautiful work we did for our client in a travel business',
      imageUrl: filter2,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 2,
      name: 'Dealership Website',
      type: 'Construction',
      content: 'Your main content here',
      imageUrl: filter3,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 3,
      name: 'Healthcare Website',
      type: 'Business',
      content: 'Your sidebar content here',
      imageUrl: filter8,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 4,
      name: 'Real Estate Website',
      type: 'Social Media',
      content: 'Your footer content here',
      imageUrl: filter10,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 5,
      name: 'Crpyto Website',
      type: 'Law',
      content: 'Your content here',
      imageUrl: filter9,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 6,
      name: 'Business Website',
      type: 'Realestate',
      content: 'Your content here',
      imageUrl: filter11,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 7,
      name: 'Food Website',
      type: 'Healthcare',
      content: 'Your content here',
      imageUrl: filter1,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 8,
      name: 'Services Website',
      type: 'construction',
      content: 'Your content here',
      imageUrl: filter5,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 9,
      name: 'Beauty App',
      type: 'Business',
      content: 'Your content here',
      imageUrl: filter6,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 10,
      name: 'Saas Website',
      type: 'Healthcare',
      content: 'Your content here',
      imageUrl: filter7,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    {
      id: 11,
      name: 'Food Website',
      type: 'Food',
      content: 'Your content here',
      imageUrl: filter12,
      link: 'https://elements.envato.com/jaynix-responsive-corporate-portfolio-email-H2RGUY',
    },
    // Repeat for additional sections
  ];
  
  console.log(websiteObjects);
  
   
    export default websiteObjects;