import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FaArrowRight } from "react-icons/fa";

const DetailServices = () => {
  const [successMessage, setSuccessMessage] = useState("");

  const data = [
    {
      title: "SEO & Digital Marketing",
      describe:
        "A great website is only effective if people can find it. We help your business gain visibility and rank higher on search engines with our SEO & Digital Marketing services. Our team conducts detailed SEO audits, performs keyword optimization, and implements content marketing strategies to increase your organic traffic. We also specialize in paid advertising strategies, including Google Ads and Social Media Ads, to drive targeted traffic and improve your online presence.",
    },
    {
      title: "Web Development",
      describe:
        "A solid digital foundation starts with a robust website. Our Web Development team builds high-performing, responsive, and scalable websites tailored to your business needs. Whether you need a custom-built website, an e-commerce platform, or a CMS-based site (like WordPress or Shopify), we’ve got you covered.",
    },
    {
      title: "UI/UX Design",
      describe:
        "User Interface (UI) and User Experience (UX) are at the core of how users interact with your website. A well-designed website can increase user engagement and drive conversions. Our team excels in creating visually appealing, intuitive, and user-friendly designs. From wireframing to prototyping and usability testing, we ensure your site provides a seamless journey for your visitors.",
    },
    {
      title: "Website Maintenance & Support",
      describe:
        "Once your website is live, it’s crucial to keep it up to date and secure. Our Website Maintenance & Support services ensure that your site stays functional, secure, and relevant. Regular updates, bug fixes, and security patches are essential to maintaining your website’s health and performance.",
    },
  ];

  return (
    <div className="bg-gray-50 pt-24 dark:bg-gray-900 text-gray-800 dark:text-gray-100 px-4 py-16">
       <Helmet>
        <title>Detail Services - Winagencydesigns</title>
        <meta
          name="description"
          content="Discover our top-notch services in web design, development, and digital strategy. Transform your business with cutting-edge technology and expert solutions."
        />
        <link rel="canonical" href="https://www.winagencydesigns.com/" />
        <meta property="og:title" content="Detail Services - Winagencydesigns" />
        <meta
          property="og:description"
          content="We offer premium web design, development, and digital solutions tailored to your business needs."
        />
        <meta property="og:url" content="https://www.winagencydesigns.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.winagencydesigns.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Detail Services - Winagencydesigns" />
        <meta
          name="twitter:description"
          content="Explore expert web development, UI/UX design, and digital transformation services."
        />
        <meta name="twitter:image" content="https://www.winagencydesigns.com/" />

        {/* JSON-LD Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Winagencydesigns",
            "url": "https://www.winagencydesigns.com/",
            "description":
              "We specialize in web design, development, and digital solutions for businesses of all sizes.",
            "publisher": {
              "@type": "Organization",
              "name": "Winagencydesigns",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.winagencydesigns.com/"
              }
            }
          })}
        </script>
      </Helmet>
      {successMessage && (
        <div className="fixed top-32 inset-0 mx-auto h-14 z-[40000] bg-green-300 text-green-800 w-1/2 rounded-lg px-4 py-1 flex items-center space-x-4">
          <p>{successMessage}</p>
        </div>
      )}
      <h1 className="text-4xl font-extrabold text-center mb-8 text-gray-800 dark:text-white">
        Our Exceptional Services
      </h1>
      <p className="text-xl text-center text-gray-600 dark:text-gray-400 max-w-4xl mx-auto mb-12">
        Explore the wide range of digital services we offer to take your business to the next level. From
        SEO to web development, our team is here to support your digital success.
      </p>

      {/* Service List */}
      <div className="max-w-7xl mx-auto space-y-12">
        {data.map((item, index) => (
          <div
            key={index}
            className="bg-white dark:bg-gray-800 p-8  transition-all hover:shadow-2xl"
          >
            <h2 className="text-3xl font-bold text-red-600 mb-4">{item.title}</h2>
            <p className="text-lg text-gray-700 dark:text-gray-300">{item.describe}</p>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-12">
        <a
          href="/"
          className="bg-orange-800 flex items-center text-white py-3 px-8 rounded-full font-semibold hover:bg-orange-600 transition duration-300 ease-in-out"
        >
          <span className="pr-2">Back Home</span> <FaArrowRight />
        </a>
      </div>
    </div>
  );
};

export default DetailServices;
