import React from 'react';
import { Banner } from '../Banner';

export const BlogSection = () => {
  return (
    <div className='bg-dark pb-10 mt-20'>
      <Banner 
        title={'Web Design Blog'} 
        img={'https://images.pexels.com/photos/6476589/pexels-photo-6476589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} 
      />
      <div className="text-black px-4 max-w-7xl mx-auto text-center md:text-left md:flex md:space-x-4 justify-between">
        <h1 className="title text-4xl green">Web Design Insights</h1>
        <div className="md:w-[60%]">
          <p className="opacity-60 pt-4 md:pt-0 text-xl">
            Welcome to our web design blog, where we share expert tips, UI/UX trends, and best practices for creating stunning and functional websites. Whether you're a designer, developer, or business owner, our articles offer valuable insights into responsive design, accessibility, performance optimization, and the latest industry tools. Stay ahead of the curve and elevate your web projects with our in-depth guides and inspiration.
          </p>
        </div>
      </div>
    </div>
  );
};
