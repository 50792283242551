import { RiMailLine, RiLockPasswordLine, RiUser2Line } from 'react-icons/ri';
import { useState } from 'react';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };
  return (
    <div className='flex  items-center  justify-center  flex-col bg-white text-orange-500 h-full w-full lg:w-1/2 px-4 py-8 rounded-r-xl'>
    <h1>Hi, Welcome to Winagencydesigns</h1>
    <p className='text-gray-400 text-xs py-2'>Sign up to stay connected with us by <strong className='font-bold text-black'>Logining In</strong>.</p>
    <form className='my-4' onSubmit={handleSubmit}>

    <div className="mb-5 flex items-center">
      <RiMailLine className="w-6 h-6 mr-3 " />
      <label htmlFor="email" className="hidden">Your email</label>
      <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pl-10" placeholder="Your email" required />
    </div>
    <div className="mb-5 flex items-center">
      <RiLockPasswordLine className="w-6 h-6 mr-3 " />
      <label htmlFor="password" className="hidden">Your password</label>
      <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pl-10" placeholder="Your password" required />
    </div>
    <div className="flex items-start mb-5">
      
      <button className="hover:text-black ms-2 text-sm font-medium text-gray-300 underline">Need an account?</button>
    </div>
    <button type="submit" className=" text-white bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center">Sign in</button>
  </form>
  </div>
  )
}
