import React from 'react'
import { Login } from './Login'
import { SignUp } from './SignUp'

export const Register = () => {
  const styles = {
    backgroundImage: 'url("https://images.pexels.com/photos/1910236/pexels-photo-1910236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    
}
 
  return (
    <div style={styles} className='h-screen bg-black text-white flex justify-center items-center'>
      <div className='flex  items-center  justify-around  w-[60%] h-[50%] rounded-xl'>
        <div className='lg:flex items-center justify-center bg-orange-200 rounded-l-xl w-1/2 h-full hidden '>
          <img src="/log.png" className='object-contain aspect-auto bg-center' alt="" />
        </div>
        {/* <SignUp/> */}
        <Login/>
      </div>
    </div>
  )
}
