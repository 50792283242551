import React, { useRef, useState } from "react";
import { ReactComponent as Img1 } from "../../ServicesImg/app.svg";
import { ReactComponent as Img2 } from "../../ServicesImg/web.svg";
import { ReactComponent as Img3 } from "../../ServicesImg/design.svg";
import { ReactComponent as Img4 } from "../../ServicesImg/marketing.svg";
import { ServiceCard } from "./ServiceCard";
import { FaArrowRight, FaCheckDouble, FaRegCopy } from "react-icons/fa";

export const Services = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const zoomRef = useRef();
  function copyText(val) {
    // Get the text field
    var text = val;

    // Copy the text inside the text field
    navigator.clipboard.writeText(text);

    // // Alert the copied text
    setTimeout(() => {
      setSuccessMessage("");
    }, 2000);
    setSuccessMessage("Copied: " + text);
  }

  const data = [
    {
      img: <Img1 />,
      title: "SEO & Digital Marketing",
      describe:
        "A great website needs traffic to be effective. We offer SEO audits, keyword optimization, content marketing, and paid advertising strategies (Google Ads, social media ads) to help your business rank higher and attract more customers.",
      color: "bg-[#fff2e2]",
    },
    {
      img: <Img2 />,
      title: "Web Development",
      describe:
        "We build high-performing, responsive, and scalable websites tailored to your business needs. Our development services include custom websites, e-commerce platforms, and CMS-based sites (WordPress, Shopify, etc.). We ensure fast load times, clean code, and mobile optimization.",
      color: "bg-[#ffe0e0]",
    },
    {
      img: <Img3 />,
      title: "UI/UX Design",
      describe:
        "User experience is key to engagement and conversions. We create intuitive, visually appealing, and user-friendly designs. Our services include wireframing, prototyping, and usability testing to ensure a seamless journey for your customers.",
      color: "bg-[#c8ebff]",
    },

    {
      img: <Img1 />,
      title: "Website Maintenance & Support",
      describe:
        "Websites require ongoing updates and maintenance to stay secure and functional. We provide regular security updates, bug fixes, speed optimization, and content updates to keep your website running smoothly.",
      color: "bg-green-200",
    },
    // {
    //   img: <Img4/>,
    //   title: "Digital Marketing",
    //   describe: "Marketing is hard? Don't worry we have a team that specialize in that. ",
    //   color: 'bg-[#eee0f7]'
    // },
  ];
  return (
    <div className="text-center py-10 px-4">
      {successMessage && (
        <div className="fixed top-32 inset-0 mx-auto h-14 z-[40000] bg-green-300 text-green-800 w-1/2 rounded-lg px-4 py-1 flex items-center space-x-4">
          <FaCheckDouble className="text-green-500" />
          <p>{successMessage}</p>
        </div>
      )}
      <h1 className="text-3xl font-bold py-5 ">Awesome Services</h1>
      <p className="text-gray-500 max-w-7xl md:w-[80%] mx-auto">
        We are your all-in-one solution for digital success. Our expert team
        specializes in website and app development, UX/UI design, and digital
        marketing. With our diverse skill set, we ensure your digital presence
        not only meets your expectations but also thrives in the way you
        envision.
      </p>
      <div className="py-10 text-start flex flex-col gap-6 md:grid md:grid-cols-2 md:w-[80%] lg:w-full  mx-auto max-w-7xl lg:grid-cols-3 xl:grid-cols-4">
        {data.map((item) => (
          <ServiceCard
            title={item.title}
            describe={item.describe}
            color={item.color}
            img={item.img}
          />
        ))}
      </div>
      <div className="flex justify-center space-x-4">
        <a
          href={"/Services"}
          target="_blank"
          rel="noreferrer"
          className="bg-orange-800 flex items-center text-white py-2 px-6 rounded-full font-semibold hover:bg-orange-600 transition"
        >
          <span className="pr-2">View Services in Details</span> <FaArrowRight/>
        </a>
      </div>
    </div>
  );
};
