import React from "react";

export const FeaturedBlog = () => {
  const blogPosts = [
    {
      id: 1,
      title: "Mastering Responsive Web Design",
      description:
        "Learn the key principles of responsive design to ensure your websites look great on all devices.",
      image:
        "https://images.pexels.com/photos/1181467/pexels-photo-1181467.jpeg",
    },
    {
      id: 2,
      title: "UI/UX Trends for 2025",
      description:
        "Stay ahead of the curve with the latest UI/UX design trends shaping the future of web design.",
      image:
        "https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg",
    },
    {
      id: 3,
      title: "Optimizing Web Performance",
      description:
        "Discover best practices for improving website speed and performance for a seamless user experience.",
      image:
        "https://images.pexels.com/photos/4065899/pexels-photo-4065899.jpeg",
    },
    {
      id: 4,
      title: "Dark Mode Design Principles",
      description:
        "Learn how to implement and optimize dark mode for better accessibility and aesthetics.",
      image:
        "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg",
    },
    {
      id: 5,
      title: "Typography in Web Design",
      description:
        "Explore the power of typography in creating engaging and readable web interfaces.",
      image:
        "https://images.pexels.com/photos/6476599/pexels-photo-6476599.jpeg",
    }
  ];

  return (
    <div className="py-14 px-4 max-w-7xl mx-auto">
      <h1 className="title text-4xl text-center md:text-left">FEATURED BLOG</h1>
      <p className="opacity-60 md:w-3/4 text-center md:text-left">
        Explore our featured web design articles covering UI/UX, performance optimization, typography, and more. Stay updated with the latest trends and insights.
      </p>

      <div className="pt-4 lg:flex lg:space-x-4">
        <div className="w-full">
          <img
            className="rounded-xl h-96 w-full object-cover"
            src="https://images.pexels.com/photos/3182821/pexels-photo-3182821.jpeg"
            alt="Designing with Purpose"
          />
          <h1 className="font-bold pt-2">
            DESIGNING WITH PURPOSE: CREATING IMPACTFUL USER EXPERIENCES
          </h1>
          <p className="opacity-60">
            Discover how thoughtful design choices can create engaging and impactful user experiences.
          </p>

          <div className="flex items-center space-x-2 pt-4">
            <img
              className="rounded-full w-10 h-10 object-cover"
              src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
              alt="Alex Johnson"
            />
            <div>
              <h1 className="font-semibold">ALEX JOHNSON</h1>
              <p className="opacity-60">UI/UX Designer</p>
            </div>
          </div>
        </div>
        <div className="pt-4 md:pt-0">
          {blogPosts.map((blogPost) => (
            <div key={blogPost.id} className="flex space-x-4 mb-3">
              <div className="w-80 h-20">
                <img
                  className="w-full h-full object-cover rounded-xl"
                  src={blogPost.image}
                  alt={blogPost.title}
                />
              </div>
              <div>
                <h1 className="font-semibold uppercase line-clamp-1">
                  {blogPost.title}
                </h1>
                <p className="opacity-60 line-clamp-2">
                  {blogPost.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
