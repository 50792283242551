import React from "react";
import { ImCheckmark2 } from "react-icons/im";

const designPrices = [
  {
    name: "Basic Design",
    price: "$500+",
    description: "Custom UI/UX design for small websites.",
    features: ["Home Page + 4 Inner Pages", "Figma or Adobe XD", "Mobile-Friendly Design"],
    buttonText: "Get Design",
  },
  {
    name: "Pro Design",
    price: "$1,000+",
    description: "Advanced design with more customization & details.",
    features: ["10+ Pages", "Interactive Prototypes", "Brand-consistent UI"],
    buttonText: "Choose Pro Design",
    highlight: true,
  },
  {
    name: "Enterprise Design",
    price: "Custom Quote",
    description: "Complex, large-scale UI/UX design solutions.",
    features: ["E-commerce UI", "Custom Illustrations", "Wireframes + Prototypes"],
    buttonText: "Request a Quote",
  },
];

const developmentPlans = [
  {
    name: "Basic Web Development",
    price: "$800+",
    description: "Perfect for small businesses or personal projects.",
    features: ["5 pages", "Mobile-friendly", "Basic SEO"],
    buttonText: "Get Started",
  },
  {
    name: "Pro Web Development",
    price: "$1,500+",
    description: "Ideal for growing businesses needing custom solutions.",
    features: ["10+ pages", "CMS integration", "Contact forms"],
    buttonText: "Choose Pro",
    highlight: true,
  },
  {
    name: "Enterprise Web Development",
    price: "Custom Quote",
    description: "For complex, large-scale projects with advanced features.",
    features: ["E-commerce", "API integrations", "Custom development"],
    buttonText: "Contact Us",
  },
];

const additionalServices = [
  {
    name: "Basic SEO",
    price: "$400/mo",
    description: "Essential SEO for small businesses to rank locally.",
    features: ["Keyword research", "On-page optimization", "Basic backlinks"],
    buttonText: "Start SEO",
  },
  {
    name: "Advanced SEO",
    price: "$1,200/mo",
    description: "Comprehensive SEO strategy for growing businesses.",
    features: ["Content strategy", "Local SEO", "High-quality backlinks"],
    buttonText: "Get Advanced SEO",
    highlight: true,
  },
  {
    name: "Enterprise SEO",
    price: "$3,000+",
    description: "Full-scale SEO for national and competitive industries.",
    features: ["Technical SEO", "Competitor analysis", "Content marketing"],
    buttonText: "Request a Quote",
  },
  {
    name: "Website Maintenance",
    price: "$150/mo",
    description: "Keep your website updated, secure, and running smoothly.",
    features: ["Bug fixes", "Performance monitoring", "Regular updates"],
    buttonText: "Subscribe",
  },
];

const PricingSection = ({ title, plans }) => (
  <div className="py-10 flex flex-col justify-center items-center bg-gray-100 dark:bg-gray-900 p-6">
    <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-8">
      {title}
    </h2>
    <div className="grid md:grid-cols-3 gap-6">
      {plans.map((plan, index) => (
        <div
          key={index}
          className={`p-6 rounded-2xl shadow-lg border transition-transform hover:scale-105 ${
            plan.highlight
              ? "bg-orange-600 text-white border-orange-700 scale-105 shadow-xl"
              : "bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700"
          }`}
        >
          <h3
            className={`text-3xl font-semibold ${
              plan.highlight ? "text-white" : "text-gray-900 dark:text-white"
            }`}
          >
            {plan.name}
          </h3>
          <p
            className={`my-2 ${
              plan.highlight ? "text-white opacity-90" : "text-gray-600 dark:text-gray-400"
            }`}
          >
            {plan.description}
          </p>
          <p
            className={`text-3xl font-bold my-4 ${
              plan.highlight ? "text-white" : "text-gray-900 dark:text-white"
            }`}
          >
            {plan.price}
          </p>
          <ul className={`space-y-2 ${plan.highlight ? "text-white" : "text-gray-600 dark:text-gray-300"}`}>
            {plan.features.map((feature, i) => (
              <li key={i} className="flex items-center space-x-2">
                <ImCheckmark2 className={plan.highlight ? "text-white" : "text-green-800"} />
                <p>{feature}</p>
              </li>
            ))}
          </ul>
          <button
            className={`mt-6 w-full py-2 rounded-lg transition ${
              plan.highlight
                ? "bg-white text-orange-600 hover:bg-gray-200"
                : "bg-orange-600 text-white hover:bg-orange-700"
            }`}
          >
            {plan.buttonText}
          </button>
        </div>
      ))}
    </div>
  </div>
);

const PricingPlans = () => {
  return (
    <div>
      <PricingSection title="Web Design Services" plans={designPrices} />
      <PricingSection title="Web Development Packages" plans={developmentPlans} />
      <PricingSection title="Additional Services" plans={additionalServices} />
    </div>
  );
};

export default PricingPlans;
