import React, { useState } from "react";
import Slider from "react-slick";
import handyman from "../assets/clientlogo/Aahandyman.png";
import ai from "../assets/clientlogo/analyticAi.png";
import american from "../assets/clientlogo/american.svg";
import sushi from "../assets/clientlogo/freshsushilogo.png";
import earlcarter from "../assets/clientlogo/Law-Offices-Of-Earl-Carter-Associates-Logo.webp";

const Client = () => {
  const [isHovered, setIsHovered] = useState(false);

  const projects = [
    { image: handyman },
    { image: ai },
    { image: american },
    { image: sushi },
    { image: earlcarter },
  ];

  const settings = {
    infinite: true,
    speed: 5900,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    dots: false,
    pauseOnHover: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="my-8 relative">
      {/* Dark gradient overlay on left and right edges */}
      <div className="absolute inset-y-0 left-0 w-40 bg-gradient-to-r from-white via-transparent to-transparent pointer-events-none z-10" />
      <div className="absolute inset-y-0 right-0 w-40 bg-gradient-to-l from-white via-transparent to-transparent pointer-events-none z-10" />

      <Slider
        {...settings}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {projects.map((project, index) => (
          <div key={index} className="px-4 m-4">
            <img src={project.image} className="w-40 h-20 object-contain" alt="" />
          </div>
        ))}
      </Slider>

      {/* Show arrows when hovered */}
      {isHovered && (
        <div className="absolute top-1/2 left-4 right-4 flex justify-between items-center z-20">
          <div className="prev-arrow cursor-pointer bg-black bg-opacity-50 text-white p-4 rounded-full">
            ←
          </div>
          <div className="next-arrow cursor-pointer bg-black bg-opacity-50 text-white p-4 rounded-full">
            →
          </div>
        </div>
      )}
    </div>
  );
};

export default Client;
